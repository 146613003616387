/* eslint-disable */
<template>
  <div>

    <div>
      <v-container>
        <v-row v-if="!transactionPass">
          <v-col cols="12" md="12">
            <div class="rounded py-5 px-5 text-center border-grey my-5">
              <h3 class="text-h2">Subtotal : $ {{ userShopingCart.totalAmount }}</h3>
            </div>
          </v-col>
          <v-col v-show="transactionInit == false" cols="12">
            <div>
              <p class="mb-1">Payment method : </p>
              <v-radio-group v-model="paymentMethod" row>
                <v-radio label="Fedapay" value="fedapay"></v-radio>
                <v-radio label="Paypal" value="paypal-card"></v-radio>
                <v-radio label="Card" value="card"></v-radio>
              </v-radio-group>
            </div>
          </v-col>

          <v-col cols="12">
            <div v-show="transactionInit == false" class="">
              <editor-x v-if="['fedapay'].includes(paymentMethod)" @success="onSuccess" :fields="fedapayFields"
                :callback="callBack" :model="model" title=" " :submit-text="'Init payment'
                  " editorClass="elevation-0" />
              <editor-x v-if="['paypal-card'].includes(paymentMethod)" @success="onSuccess" :fields="fedapayFields"
                :callback="callBack" :model="model" title=" " :submit-text="'Init payment'
                  " editorClass="elevation-0" />
              <div v-show="['card'].includes(paymentMethod)" class="white">
                <div class="px-md-5 ">
                  <div class="container">
                    <div class="mb-5">
                      <h3 class="mb-3">Card Information</h3>
                      <div id="card-element">
                      </div>
                      <div id="error-message" class="red--text"></div>
                    </div>
                    <h3>Customer Information </h3>
                  </div>
                </div>
                <editor-x @success="onSuccess" :fields="cardFields" :callback="callBack" :model="model" title=" "
                  :submit-text="'Init payment'
                    " editorClass="elevation-0 mt-0 pt-0" />
              </div>


            </div>
          </v-col>
          <v-col v-show="paymentMethod == 'paypal-card' && paymentDone == false" class="d-flex justify-center"
            cols="12">

            <v-col v-show="paymentMethod == 'paypal-card' && transactionInit == true" cols="8">
              <div class="mb-3">
                <!-- <h3>Please choose between credit card and PayPal to finalize your transaction.</h3> -->
                <h3 class="text-center">We are waiting for the completion of your order in the Paypal checkout URL.</h3>
                <div class="d-flex justify-center ">
                  <div>
                    <v-img contain height="35rem" :src="require('../../assets/img/Empty-cart.png')"></v-img>
                  </div>
                </div>
              </div>
              <!-- <div ref="paypal"></div> -->
            </v-col>
          </v-col>
          <v-col v-show="paymentMethod == 'fedapay' && paymentDone == false" class="d-flex justify-center" cols="12">

            <v-col v-show="paymentMethod == 'fedapay' && transactionInit == true" cols="8">
              <div class="mb-3">
                <!-- <h3>Please choose between credit card and PayPal to finalize your transaction.</h3> -->
                <h3 class="text-center">We are waiting for the completion of your order in the Fedapay checkout URL.
                </h3>
                <div class="d-flex justify-center ">
                  <div>
                    <v-img contain height="35rem" :src="require('../../assets/img/Empty-cart.png')"></v-img>
                  </div>
                </div>
              </div>
              <!-- <div ref="paypal"></div> -->
            </v-col>
          </v-col>
          <v-col v-show="paymentMethod == 'card' && paymentDone == false" class="d-flex justify-center" cols="12">

            <v-col v-show="paymentMethod == 'card' && transactionInit == true" cols="8">
              <div class="mb-3">
                <!-- <h3>Please choose between credit card and PayPal to finalize your transaction.</h3> -->
                <div class="d-flex justify-center">
                  <v-btn loading icon> </v-btn>
                </div>
                <div class="d-flex justify-center ">
                  <div>
                    <v-img contain height="35rem" :src="require('../../assets/img/Empty-cart.png')"></v-img>
                  </div>
                </div>
              </div>
              <!-- <div ref="paypal"></div> -->
            </v-col>
          </v-col>

        </v-row>
        <div v-if="transactionPass">
          <div class="d-flex justify-center align-center">
            <v-icon size="6rem" color="green">mdi-check-circle</v-icon>
          </div>
          <div class="text-center mt-3">
            <h3>Your subscription has been successfully processed. </h3>
            <p>You will be redirected to the page of your active plan in <strong> {{ redirectionSeconde }}</strong>
              seconds. You will be able to download your receipt on that page.</p>
          </div>
        </div>
      </v-container>




      <v-dialog max-width="40rem" v-model="warningModel">
        <v-alert class="white my-0 py-5" text dense color="read" icon="mdi-alert" border="left">
          <p class="">{{ "Attention: Activation of a new subscription" }}</p>
          <v-divider class="my-4 read" style="opacity: 0.22"></v-divider>

          <div>
            <div>
              <p class="mb-0">
                {{ `Before confirming your new subscription, please note that this will automatically deactivate your
                current subscription.Your data will be preserved, but please ensure you understand the implications of
                this change.` }}
              </p>
            </div>
          </div>
          <div class="d-flex justify-end mt-5">
            <v-btn color="read" outlined @click="() => { warningModel = false }">{{ "Continue" }}</v-btn>
          </div>
        </v-alert>
      </v-dialog>
    </div>



  </div>
</template>
<script>
import { methods, rules } from "@/utils/constant.js";
import { mapGetters, mapActions } from "vuex";
import { createTransaction, transactionFeedback ,cardTransactionFeedback} from "@/api/user.js";

import EditorX from "../../components/universal/EditorX.vue";

export default {
  props: {

  },
  components: {
    EditorX
  },
  data() {
    return {
      loaded: false,
      paidFor: false,
      paymentDone: false,
      rules: rules,
      stripeInstance: null,
      cardElement: null,
      paymentMethod: "fedapay",
      callBack: createTransaction,
      model: {
      },
      transactionInit: false,

      transactionId: null,
      transactionNumber: null,
      transactionPass: null,
      redirectionInterval: null,
      redirectionSeconde: 5,
      warningModel: false

    };
  },
  computed: {
    ...mapGetters([
      "userProfile",
      "projects",
      "workspaces",
      "guestWorkspaces",
      "workspaceInvitations",
      "userShopingCart",
      "activesSubscription",
    ]),

    fedapayFields() {
      return [

        {
          name: "paymentMethod",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value: this.paymentMethod,
        },


        {
          name: "lastName",
          label: "Last Name",
          placeholder: "Last Name",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",

        },
        {
          name: "firstName",
          label: "First Name",
          placeholder: "First Name",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
          ],
        },
        {
          name: "email",
          label: "Email",
          placeholder: "Email",
          type: "String",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
          ],
        },
        // {
        //   name: "phoneCountry",
        //   label: "Phone Country",
        //   placeholder: "BJ",
        //   type: "Select",
        //   col: 2,

        //   multipleCol: 2,
        //   multipleMdCol: 3,
        //   isRequiredForUpdate: true,
        //   isRequiredForAdd: true,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   select: {
        //     data: ["BJ"],
        //   },
        //   rules: [
        //   ],
        //   default: "BJ"
        // },
        {
          name: "phoneNumber",
          label: "Phone Number",
          placeholder: "Phone Number",
          type: "Phone",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
          ],
        },

      ]
    },
    cardFields() {
      return [


        {
          name: "lastName",
          label: "Last Name",
          placeholder: "Last Name",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
        },
        {
          name: "firstName",
          label: "First Name",
          placeholder: "First Name",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
          ],
        },
        {
          name: "email",
          label: "Email",
          placeholder: "Email",
          type: "String",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
          ],
        },

        {
          name: "phoneNumber",
          label: "Phone Number",
          placeholder: "Phone Number",
          type: "Phone",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
          ],
        },
        {
          name: "paymentMethod",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value: this.paymentMethod,
        },

      ]
    },

  },
  methods: {
    ...mapActions([
      "getUserProfile",
      "getMyShopingCart",
      "getActiveSubscription",
    ]),
    async initialize() {
      try {
        this.loading = true;
        await this.getMyShopingCart();

        // await this.getProjects();
      } catch (error) {
        console.log("initialize home", error);
        methods.dispatchError(this.$store);
      } finally {
        this.loading = false;
        this.showWorkspace = false;
        this.workspaceModel = {};
      }
    },
    async makeTransactionFeedBack(schema) {
      try {
        this.loading = true;
        await transactionFeedback(schema)
        methods.dispatchSuccess(this.$store, "Transaction passed successfully.");

        this.transactionPass = true
        this.redirectionSeconde = 5
        this.redirectionInterval = setInterval(async () => {
          this.redirectionSeconde--
          if (this.redirectionSeconde < 1) {
            clearInterval(this.redirectionInterval)
            await this.getActiveSubscription()
            this.$router.push('/active-subscription')
          }
        }, 1000)
      } catch (error) {
        console.log(error)
        methods.dispatchError(this.$store, "Failed when make transaction");
      } finally {
        this.loading = false;

      }
    },
    async makeCardTransactionFeedBack(schema) {
      try {
        this.loading = true;
        await cardTransactionFeedback(schema)
        methods.dispatchSuccess(this.$store, "Transaction passed successfully.");

        this.successTransaction()
      } catch (error) {
        console.log(error)
        methods.dispatchError(this.$store, "Failed when make transaction");
      } finally {
        this.loading = false;

      }
    },
    async successTransaction() {
      try {

        this.transactionPass = true
        this.redirectionSeconde = 5
        this.redirectionInterval = setInterval(async () => {
          this.redirectionSeconde--
          if (this.redirectionSeconde < 1) {
            clearInterval(this.redirectionInterval)
            await this.getActiveSubscription()
            this.$router.push('/active-subscription')
          }
        }, 1000)
      } catch (error) {
        console.log(error)
        methods.dispatchError(this.$store, "Failed when make transaction");
      } finally {
        this.loading = false;

      }
    },
    async onSuccess(response) {
      console.log(response)
      if (response.paymentMethod == 'card') {
        this.transactionInit = true
        this.transactionId = response.transaction._id
       
        await this.cardCallBack(response.clientSecret,response.paymentIntentId)

      } else {
        methods.clickURL(response.redirectionUrl)
        this.transactionInit = true

      }
      // this.transactionNumber = response.number


      // if (this.paymentMethod == 'fedapay') {
      //   await this.payWithFedapay()
      // }


      // this.initialize();
      // this.$emit("refresh");
    },
    setLoaded: function () {
      const makeTransactionFeedBack = this.makeTransactionFeedBack

      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: 'Orangcode payment : ',
                  amount: {
                    currency_code: "USD",
                    value: Math.abs(this.userShopingCart.totalAmount)
                  }
                }
              ]
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();

            this.data;

            this.paidFor = true;



            console.log(order);


            await makeTransactionFeedBack({
              id: this.transactionId,
              schema: {
                status: "transaction.approved",
                reference: order.id,
              }
            })



          },
          onError: async err => {
            await makeTransactionFeedBack({
              id: this.transactionId,
              schema: {
                status: "transaction.canceled",
              }
            })
            console.log(err);
          }
        })
        .render(this.$refs.paypal);
    },
    setStripeCardLoaded: async function () {
      // if (window.Stripe) {

      // }

    },
    payWithFedapay() {
      const makeTransactionFeedBack = this.makeTransactionFeedBack
      const xofAmount = this.userShopingCart.totalAmount * 610
      let widget = window.FedaPay.init({
        public_key: process.env.FEDAPAY_PUBLIC_KEY,
        transaction: {
          amount: Math.abs(Math.round(xofAmount)),
          description: `Orangcode payment:`,
          _metadata: {
            transaction_id: this.transactionId
          },
        },
        customer: {
          firstname: this.model.firstName,
          lastname: this.model.lastName,
          email: this.model.email,
          phone: this.model.phoneNumber,
        },

        async onComplete(response) {
          console.log(response)

          if (response.transaction.status === "approved") {
            await makeTransactionFeedBack({
              id: response.transaction.custom_metadata.transaction_id,
              schema: {
                status: "transaction.approved",
                reference: response.transaction.reference,
                // transactionId: response.transaction.id,
              }
            })
          } else {
            methods.dispatchError(
              this.$store,
              "Transaction failed. Try again"
            );
          }
        }
      });


      widget.open();

    },
    async cardCallBack(clientSecret,paymentIntentId) {

      const { error, paymentMethod } = await this.stripeInstance.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
      });

      // if (error) {
      //   this.error = error.message;
      //   return;
      // }

      // const {
      //   error,
      //   paymentIntent
      // } = await this.stripeInstance.confirmCardPayment(clientSecret, {
      //   payment_method: {
      //     card: this.cardElement
      //   }
      // });

      if (error) {
        document.getElementById('error-message').textContent = error.message;
      } else {
        await this.makeCardTransactionFeedBack({
          id: this.transactionId,
          schema: {
            // status: "Approved",
            paymentMethodId: paymentMethod.id,
            clientSecret: clientSecret,
            paymentIntentId:paymentIntentId

          }
        })
      }
    }
  },
  watch: {
    activesSubscription() {
      if (this.activesSubscription && !["free"].includes(this.activesSubscription.plan.toLowerCase())) {
        this.warningModel = true
      }
    },
    // paymentMethod() {
    //   if (['card'].includes(this.paymentMethod)) {
    //     const publicKey = "pk_test_51PU1pgGVTADaZC6Tihnw9If2K7VVgylR1zHnBypnJMBVZfPglqHBslivAKqBHcNxkcStXcwPCHyLh9wi2pb7FqK700RihlHsI9"
    //     const stripe = window.Stripe(publicKey);
    //     const elements = stripe.elements();
    //     const cardElement = elements.create('card');
    //     cardElement.mount('#card-element');
    //   }
    // }

  },
  sockets: {
    purchaseFeedBack(payload) {

      if (payload.status == 'Completed') {
        this.successTransaction()
      }
      if (["Canceled", "Declined"].includes(payload.status)) {
        this.$router.push('/subscription-failed')
      }
    },
  },
  mounted() {
    this.initialize()

    const publicKey = process.env.STRIPE_PUBLIC_KEY || "pk_test_51PU1pgGVTADaZC6Tihnw9If2K7VVgylR1zHnBypnJMBVZfPglqHBslivAKqBHcNxkcStXcwPCHyLh9wi2pb7FqK700RihlHsI9"
    const stripe = window.Stripe(publicKey);
    const elements = stripe.elements();
    this.stripeInstance = stripe

    const cardElement = elements.create('card');
    cardElement.mount('#card-element');
    this.cardElement = cardElement

    // const script = document.createElement("script");
    // script.src =
    //   `https://js.stripe.com/v3/`;
    // script.addEventListener("load", this.setStripeCardLoaded);
    // document.body.appendChild(script);




    // const script = document.createElement("script");
    // script.src =
    //   `https://www.paypal.com/sdk/js?client-id=${process.env.PAYPAL_CLIENT_ID}`;
    // script.addEventListener("load", this.setLoaded);
    // document.body.appendChild(script);

    // const scriptFedapayCheckout = document.createElement("script");
    // scriptFedapayCheckout.src =
    //   "https://cdn.fedapay.com/checkout.js?v=1.1.7";
    // document.body.appendChild(scriptFedapayCheckout);






  },
  beforeDestroy() {
    if (this.redirectionInterval) {
      clearInterval(this.redirectionInterval)
    }
  }
};
</script>